import React from "react";
// import {EventDispatcher} from "../../../../events/EventDispatcher";
// import PropertyChangeEvent from "../../../../events/PropertyChangeEvent";
import * as _ from 'lodash';
// import {IObject} from "../../../../tsii/IObject";
// import {Event2} from "../../../../events/Event2";
import {EventDispatcher} from "../../events/EventDispatcher";
import {PropertyChangeEvent} from "../../events/PropertyChangeEvent";
import {IObject} from "../../tsii/IObject";
import {DataEvent} from "../../events/DataEvent";
import {log} from "node:util";
export class ComponentBase<P, S> extends React.Component<P, S> {
    // public state:Record<string | number | symbol, any>;// = {};
    // public state:S|undefined;
    public dispatcher:EventDispatcher = new EventDispatcher();
    protected _isMounted:boolean = false;
    // protected _waitingStateObject:Record<string | number | symbol, any> = {};
    protected _waitingStateObject:IObject = {};
    protected sstate:any
    constructor(props: any) {
        super(props);
        this.state = {} as S
    }
    static get eventIsMountedChange() {
        return "eventIsMountedChange"
    };
    static get eventStateUpdated(){
        return "eventStateUpdated";
    }

    get isMounted() {
        return this._isMounted;
    }

    set isMounted(value) {
        if (this._isMounted === value) return;
        let old = this._isMounted;
        this._isMounted = value;
        let event = new PropertyChangeEvent<boolean>(ComponentBase.eventIsMountedChange, old, value, PropertyChangeEvent.kindUpdate);
        this.dispatcher.dispatchEvent(event);
        this.onMountedChange(event)
    }
    onMountedChange = (event:PropertyChangeEvent<boolean>) =>{
        if(event.newValue){
            this.onResume()
        }else{
            this.onPause()
        }
    }
    onResume () {
        this.addEvents()
    }
    onPause () {
        this.removeEvents()
    }
    addEvents (){

    }
    removeEvents (){

    }
    componentWillUnmount = ()=>{
        this.isMounted = false;
    }
    componentDidMount () {
        this.isMounted = true;
        this._laterCallSetState();
    }
    setState = (state:Record<any, any>) => {
        // _.assign(this._waitingStateObject, state);
        for(let key in state){
            this._waitingStateObject[key] = state[key]
        }
        setTimeout(this._laterCallSetState, 1);
    }
    _laterCallSetState = () => {
        if (!this.isMounted) return;
        for(let key in this._waitingStateObject){
            if(this.sstate){
                this.sstate[key] = this._waitingStateObject[key];
            }
            this.state = this.state || {}
            // @ts-ignore
            this.state[key] = this._waitingStateObject[key];
        }
        super.setState(this._waitingStateObject as any);
        // super.setState(this.state);
        let updatedObj = this._waitingStateObject
        this._waitingStateObject = {};
        let event = new DataEvent(ComponentBase.eventStateUpdated);
        this.dispatcher.dispatchEvent(event);
        this._onStateUpdate(updatedObj);
    }
    _onStateUpdate(updatedObj:any){

    }

}
